<template>
  <div class="container-home d-flex justify-content-center align-items-center vh-100">
    <div class="container-image text-center">
      <h1>Softorres</h1>
      <p><i>Software, Innovación y Vanguardia</i></p>
      <p class="text-contact-info pb-5">
        <strong>Ventas y Contacto</strong> <br />
        <a href="mailto:contacto@softorres.com">contacto@softorres.com</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexHome",
};
</script>

<style scoped>
h1,
p {
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 10em;
}

p {
  font-size: 1.5em;
}

@media (max-width: 768px) {
  h1 {
    font-size: 4em; /* Reduce el tamaño de la fuente en pantallas pequeñas */
  }

  p {
    font-size: 1.2em; /* Ajusta el tamaño del texto */
  }
}
</style>
